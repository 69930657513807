import { PartialMessage } from '@protobuf-ts/runtime';
import { ListSchoolStaffTrainingProgressResponse } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import {
  TrainingModuleState,
  TrainingProgress,
  UpdateTrainingProgressForCurrentUserRequest,
} from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { v5 as uuidv5 } from 'uuid';

import { useTrainingContext } from './Context';
import { ModuleSpec, ModuleState, ModuleStepState, TrainingSummary } from './types';

const stepNameNamespace = '444306ed-5437-4bda-aa74-5bbc917572b0';

export const makeModuleStates = (moduleSpecs: ModuleSpec[], data?: TrainingProgress) => {
  if (!data) {
    return data;
  }

  return moduleSpecs.map<ModuleState>(moduleSpec => {
    const progress: TrainingModuleState = data.moduleData[moduleSpec.name] || {
      name: moduleSpec.name,
      completedSteps: [],
      annotations: {},
    };

    const moduleComplete = !!progress.completedTime;

    const moduleState: ModuleState = {
      spec: moduleSpec,
      state: progress,
      complete: moduleComplete,
      steps: [],
    };

    moduleState.steps = moduleSpec.steps.map<ModuleStepState>(stepSpec => ({
      complete: moduleComplete || progress.completedSteps.includes(stepSpec.id),
      module: moduleState,
      spec: stepSpec,
      stepName: uuidv5(`${moduleSpec.name}/${stepSpec.id}`, stepNameNamespace),
    }));

    return moduleState;
  });
};

type useCurrentTrainingStateOptions<T = ModuleState[]> = Pick<
  UseQueryOptions<ModuleState[], unknown, T>,
  'suspense' | 'enabled'
>;
export const useCurrentTrainingState = <T = ModuleState[]>(
  options: useCurrentTrainingStateOptions<T>,
) => {
  const { moduleSpecs, trainingProgressClient } = useTrainingContext();
  return useQuery({
    queryKey: ['trainingprogress', 'currentuser'],
    queryFn: async () => trainingProgressClient.getTrainingProgressForCurrentUser({}).response,
    select: data => makeModuleStates(moduleSpecs, data),
    structuralSharing: false,
    ...options,
  });
};

export const useCompleteTrainingStep = () => {
  const { trainingProgressClient, queryClient, schoolID } = useTrainingContext();
  return useMutation({
    mutationFn: async (stepState: ModuleStepState) => {
      const moduleState = stepState.module.state;
      const moduleSpec = stepState.module.spec;
      // We check if the step is in the list of the completed steps rather than
      // stepState.complete, so that we will still mark the step as complete even
      // if the module already is.
      const stepComplete = moduleState.completedSteps.includes(stepState.spec.id);
      if (stepComplete) {
        // Step already complete, nothing to update
        return null;
      }

      const mask = FieldMask.create({ paths: ['module_data.completed_steps'] });
      const now = Timestamp.now();

      moduleState.completedSteps.push(stepState.spec.id);
      const modNowComplete = areAllStepsComplete(moduleSpec, moduleState.completedSteps);

      if (!stepState.module.complete && modNowComplete) {
        moduleState.completedTime = now;
        mask.paths.push('module_data.completed_time');
      }

      const annotations: { [key: string]: string } = {};

      // Add the school annotation
      if (schoolID) {
        annotations['sparx.schools/name'] = `schools/${schoolID}`;
        mask.paths.push('annotations');
      }

      const req = UpdateTrainingProgressForCurrentUserRequest.create({
        trainingProgress: {
          moduleData: {
            [moduleSpec.name]: moduleState,
          },
          annotations: annotations,
        },
        updateMask: mask,
      });
      return trainingProgressClient.updateTrainingProgressForCurrentUser(req).response;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueryData(['trainingprogress', 'currentuser'], data);
        queryClient.setQueryData(['trainingprogress', 'currentuser', 'v2'], data);
      }
    },
  });
};

// Resets module training progress, takes an array of module names to reset, pass
//  in an empty array to reset all modules
export const useResetTrainingProgress = () => {
  const { moduleSpecs, trainingProgressClient } = useTrainingContext();
  return useMutation({
    mutationFn: async (modules: string[]) => {
      const moduleData: PartialMessage<{
        [key: string]: TrainingModuleState;
      }> = {};

      if (modules.length > 0) {
        for (const m of modules) {
          moduleData[m] = {};
        }
      } else {
        for (const m of moduleSpecs) {
          moduleData[m.name] = {};
        }
      }

      const req = UpdateTrainingProgressForCurrentUserRequest.create({
        trainingProgress: {
          moduleData,
        },
        updateMask: FieldMask.create({
          paths: [
            'module_data.completed_steps',
            'module_data.completed_time',
            'module_data.dismiss_time',
          ],
        }),
      });

      return trainingProgressClient.updateTrainingProgressForCurrentUser(req).response;
    },
    onSuccess: () => {
      // Cause a reload, this is mainly because some components keep state in
      // refs which wouldn't otherwise be reset
      location.reload();
    },
    onError: e => {
      console.error('Error resetting training progress:', e);
    },
  });
};

const areAllStepsComplete = (module: ModuleSpec, completedSteps: string[]) =>
  module.steps.every(t => completedSteps.includes(t.id));

const trainingSummaryForStaffId = (
  staffId: string,
  moduleSpecs: ModuleSpec[],
  data: ListSchoolStaffTrainingProgressResponse,
) => {
  const trainingProgress = (data.trainingProgresses || []).find(p =>
    p.aliases.some(a => a.split('/')[1] === staffId),
  );

  const states = makeModuleStates(moduleSpecs, trainingProgress);
  const numComplete = states ? states.filter(v => v.complete).length : 0;

  const summary: TrainingSummary = {
    completed: numComplete,
    total: moduleSpecs.filter(module => !module.isOptional).length,
    modules: states || [],
  };

  return summary;
};

const useAllStaffTrainingProgress = ({
  enabled,
  ...restOptions
}: useCurrentTrainingStateOptions<ListSchoolStaffTrainingProgressResponse>) => {
  const { staffClient, schoolID } = useTrainingContext();
  return useQuery({
    queryKey: ['trainingprogress', 'allstaff'],
    queryFn: async () =>
      staffClient?.listSchoolStaffTrainingProgress({
        school: `schools/${schoolID}`,
      }).response,
    enabled: staffClient && enabled,
    ...restOptions,
  });
};

export const useStaffTrainingSummary = <T = TrainingSummary>(
  staffId: string,
  options: useCurrentTrainingStateOptions<T>,
) => {
  const { moduleSpecs } = useTrainingContext();

  const { data, ...rest } = useAllStaffTrainingProgress(options);

  // summarise the progress data
  const summary = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return trainingSummaryForStaffId(staffId, moduleSpecs, data);
  }, [data, moduleSpecs, staffId]);

  return { data: summary, ...rest } as UseQueryResult<TrainingSummary>;
};

export const useStaffTrainingSummaries = <T = Record<string, TrainingSummary>>(
  staffIds: string[],
  options: useCurrentTrainingStateOptions<T>,
) => {
  const { moduleSpecs } = useTrainingContext();

  const { data, ...rest } = useAllStaffTrainingProgress(options);

  // summarise the progress data
  const summaries = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return staffIds.reduce<Record<string, TrainingSummary>>((acc, staffId) => {
      acc[staffId] = trainingSummaryForStaffId(staffId, moduleSpecs, data);
      return acc;
    }, {});
  }, [data, moduleSpecs, staffIds]);

  return { data: summaries, ...rest } as UseQueryResult<Record<string, TrainingSummary>>;
};
