import {
  faCheck,
  faChevronRight,
  faGraduationCap,
  faPlay,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import {
  ProgressSegments,
  ProgressSegmentType,
} from 'components/progress-segments/progress-segments';
import { useUser } from 'queries/session';
import { useMemo, useState } from 'react';
import { View } from 'views';
import { useCurrentTrainingProgress } from 'views/teacher/teacher-training-view/hooks/training-progress';
import { makePathForStep } from 'views/teacher/teacher-training-view/utils';
import { pathForView } from 'views/views';

import { clickedButton, dismissedWidget } from './events';
import styles from './training-dashboard-widget.module.css';

const makeLocalStorageKey = (userId?: string) => `training-dashboard-widget-dismissed-${userId}`;

export const TrainingDashboardWidget = () => {
  const user = useUser();
  const localStorageKey = useMemo(() => makeLocalStorageKey(user?.userId), [user?.userId]);
  const dismissedDate = useMemo(() => localStorage.getItem(localStorageKey), [localStorageKey]);
  const [justDismissed, setJustDismissed] = useState(false);
  const { allComplete, essential, usingSparxReader, completeStepsCount } =
    useCurrentTrainingProgress();
  const { sendEvent } = useClientEvent();

  const currentModule = essential.complete ? usingSparxReader : essential;

  let firstIncompleteStep = essential?.steps?.[essential?.firstIncompleteIx];
  if (essential.complete) {
    firstIncompleteStep = usingSparxReader?.steps?.[usingSparxReader?.firstIncompleteIx];
  }

  // If there is an incomplete step, link directly to it. Otherwise, just link to the training page.
  const linkPath = firstIncompleteStep
    ? makePathForStep(firstIncompleteStep)
    : pathForView(View.TeacherTraining);

  const upNextImage = firstIncompleteStep?.spec.upNextImage;

  if (user === null || dismissedDate !== null || allComplete) {
    return null;
  }

  const onDismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setJustDismissed(true);
    sendEvent(dismissedWidget());
  };

  if (justDismissed) {
    return (
      <div className={styles.DismissedBanner}>
        <FontAwesomeIcon
          icon={faCheck}
          style={{ marginRight: 10 }}
          size="lg"
          className={styles.DismissedTick}
        />
        Training progress will no longer show. You can access training using the menu in the top
        right.
      </div>
    );
  }

  return (
    <>
      <h3 className={styles.Label}>
        <FontAwesomeIcon icon={faGraduationCap} /> Your Sparx Reader Training
      </h3>
      <div className={styles.Banner}>
        {essential.complete && (
          <button className={styles.DismissButton} onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <div className={styles.Content}>
          <div className={styles.Title}>
            <span className={styles.TitleText}>
              <strong>
                {essential.complete ? 'Level 2: Using Sparx Reader' : 'Level 1: Essential Training'}
              </strong>
            </span>
            {currentModule.steps && (
              <ProgressSegments
                segments={currentModule.steps.map(step =>
                  step.complete ? ProgressSegmentType.Success : ProgressSegmentType.Incomplete,
                )}
                background="var(--white-opacity-20)"
              />
            )}
          </div>

          <div className={styles.Actions}>
            <Link to={linkPath} analyticsEvent={clickedButton()}>
              <Button
                variant="inverse"
                analyticsEvent={undefined}
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              >
                {completeStepsCount > 0 ? 'Continue training' : 'Start essential training'}
              </Button>
            </Link>

            {completeStepsCount === 0 ? (
              <p className={styles.Next}>
                <em>Up first: What is Sparx Reader?</em>
              </p>
            ) : (
              <p className={styles.Next}>
                <em>Up next: {firstIncompleteStep?.spec?.title}</em>
              </p>
            )}
          </div>
        </div>
        {upNextImage && (
          <Link to={linkPath} analyticsEvent={clickedButton()}>
            <div className={styles.Image}>
              <img src={upNextImage} />
              <div className={styles.Play}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};
